module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DIBS Capital MarketPlace","short_name":"DIBS Capital","description":"DIBS Capital Tokenization","icons":[{"src":"src/images/favicon/icon32.png","sizes":"32x32","type":"image/png"}],"icon":"src/images/favicon/icon32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fbe95270ab00f92a0a8037a2742efc27"},
    }]
